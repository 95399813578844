import http from '../clients/Axios'

export default class LaporanPembelianService {
  async exportLaporanDaftarPembelian(start_date, end_date, supplier_id) {
    if (!supplier_id) supplier_id = 0
    return http({
      url: `/report/pembelian/daftarpembelian/print?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDaftarPembelianExcel(start_date, end_date, supplier_id) {
    if (!supplier_id) supplier_id = 0
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/pembelian/daftarpembelian/export?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianDetail(start_date, end_date, supplier_id) {
    if (!supplier_id) supplier_id = 0
    return http({
      url: `/report/pembelian/detailpembelian/print?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianDetailExcel(start_date, end_date, supplier_id) {
    if (!supplier_id) supplier_id = 0
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/pembelian/detailpembelian/export?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianSupplier(start_date, end_date, supplier_id) {
    return http({
      url: `/report/pembelian/daftarpembeliansupplier/print?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianSupplierExcel(start_date, end_date, supplier_id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/pembelian/daftarpembeliansupplier/export?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDetailPembelianSupplier(
    start_date,
    end_date,
    supplier_id
  ) {
    return http({
      url: `/report/pembelian/detailpembeliansupplier/print?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDetailPembelianSupplierExcel(
    start_date,
    end_date,
    supplier_id
  ) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/pembelian/detailpembeliansupplier/export?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[supplier_id]=${supplier_id}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDetailPembelianGolongan(
    start_date,
    end_date,
    golongan_id
  ) {
    return http({
      url: `/report/pembelian/detailpembeliangolongan/print?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDetailPembelianGolonganExcel(
    start_date,
    end_date,
    golongan_id
  ) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/pembelian/detailpembeliangolongan/export?filter[date_from]=${start_date}&filter[date_to]=${end_date}&filter[golongan_id]=${golongan_id}&t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianBarang(obat_id, periode_from, periode_to) {
    const params = new URLSearchParams()

    params.append('filter[obat_id]', obat_id)
    params.append('filter[periode_from]', periode_from)
    params.append('filter[periode_to]', periode_to)

    return http({
      url: `/report/pembelian/barang/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianBarangExcel(obat_id, periode_from, periode_to) {
    const params = new URLSearchParams()

    const unixTime = Math.floor(Date.now() / 1000)
    params.append('filter[obat_id]', obat_id)
    params.append('filter[periode_from]', periode_from)
    params.append('filter[periode_to]', periode_to)
    params.append('t', unixTime)

    return http({
      url: `/report/pembelian/barang/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianBarangSupplier(
    obat_id,
    supplier_id,
    periode_from,
    periode_to
  ) {
    const params = new URLSearchParams()

    params.append('filter[obat_id]', obat_id)
    params.append('filter[supplier_id]', supplier_id)
    params.append('filter[periode_from]', periode_from)
    params.append('filter[periode_to]', periode_to)

    return http({
      url: `/report/pembelian/barang/supplier/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianBarangSupplierExcel(
    obat_id,
    supplier_id,
    periode_from,
    periode_to
  ) {
    const params = new URLSearchParams()

    const unixTime = Math.floor(Date.now() / 1000)
    params.append('filter[obat_id]', obat_id)
    params.append('filter[supplier_id]', supplier_id)
    params.append('filter[periode_from]', periode_from)
    params.append('filter[periode_to]', periode_to)
    params.append('t', unixTime)

    return http({
      url: `/report/pembelian/barang/supplier/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianPabrik(pabrik_id, periode_from, periode_to) {
    const params = new URLSearchParams()

    params.append('filter[pabrik_id]', pabrik_id)
    params.append('filter[periode_from]', periode_from)
    params.append('filter[periode_to]', periode_to)

    return http({
      url: `/report/pembelian/pabrik/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanPembelianPabrikExcel(pabrik_id, periode_from, periode_to) {
    const params = new URLSearchParams()

    const unixTime = Math.floor(Date.now() / 1000)
    params.append('filter[pabrik_id]', pabrik_id)
    params.append('filter[periode_from]', periode_from)
    params.append('filter[periode_to]', periode_to)
    params.append('t', unixTime)

    return http({
      url: `/report/pembelian/pabrik/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
}
